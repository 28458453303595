import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyANHlO2ACIk0upZMTeCMO6nZc3K08Ohcbs",
  authDomain: "cst-landingpage.firebaseapp.com",
  projectId: "cst-landingpage"
};

export default !firebase.apps.length
  ? firebase.initializeApp(config)
  : firebase.app();
