import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import InternetExplorerDialog from "./components/InternetExplorerDialog";

const Footer = () => {
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    if (
      ua.indexOf("Trident/7.0") > -1 ||
      ua.indexOf("Trident/6.0") > -1 ||
      ua.indexOf("Trident/5.0") > -1
    ) {
      setOpenDialog(true);
    }
  }, []);

  return (
    <Wrapper>
      <Grid
        container
        justify={"center"}
        direction="row"
        className="menu_pie"
        style={{ backgroundColor: "#6a84a1" }}
      >
        <Grid item xs={12} lg={7} xl={5}>
          <Grid
            container
            justify={"center"}
            direction="row"
            style={{ width: "100%" }}
          >
            <Grid
              style={{ margin: "25px 0px 0px 0px" }}
              className="reactFooterColumns"
              item
              xs={6}
              sm={2}
            >
              <h2>The Company</h2>
              <p className="pAcontainer">
                <a href="Company.html">The Company</a>
              </p>
            </Grid>
            <Grid
              style={{ margin: "25px 0px 0px 0px" }}
              className="reactFooterColumns"
              item
              xs={6}
              sm={3}
            >
              <h2>Services</h2>

              <p className="pAcontainer">
                <a href="Ground-Handling.html">Ground Handling</a>
              </p>
              <p className="pAcontainer">
                <a href="International-Permits.html">International Permits</a>
              </p>
              <p className="pAcontainer">
                <a href="Airspace-Overflight-Fees.html">
                  Airspace & Overflight Fees
                </a>
              </p>
              <p className="pAcontainer">
                <a
                  href="https://members.cstflightservices.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Online Services
                </a>
              </p>
              <p className="pAcontainer">
                <a href="Guided-Expeditions.html">Guided Expeditions</a>
              </p>
            </Grid>
            <Grid
              style={{ margin: "25px 0px 0px 0px" }}
              className="reactFooterColumns"
              item
              xs={6}
              sm={2}
            >
              <h2>Others</h2>
              <p className="pAcontainer">
                <a href="https://services.cst-apps.com/quotes?clientName=cst">
                  Request a Quote
                </a>
              </p>
              <p className="pAcontainer">
                <a href="Events.html">CST Events</a>
              </p>
              <p className="pAcontainer">
                <a href="Contact.html">Contact Us</a>
              </p>
              <p className="pAcontainer">
                <a href="Terms-Service.html">Terms of Service</a>
              </p>
              <p className="pAcontainer">
                <a href="Privacy-Policy.html">Privacy Policy</a>
              </p>
            </Grid>
            <Grid
              style={{ margin: "25px 0px 0px 0px" }}
              className="reactFooterColumns infoCST"
              item
              xs={6}
              sm={4}
            >
              <p> Mako Leasing Company I, LLC</p>
              <p> D/B/A CST Flight Services</p>
              <p> 6303 Blue Lagoon Drive Suite 400</p>
              <p> Miami, FL 33126</p>
              <br />
              <p> U.S. Tel. (786) 206.6147</p>
              <p> Mex.Tel. (222) 944.3402</p>
              <a href="mailto:info@cstflightservices.com?Subject=Contact%20from%20CST%20Flight%20Services%20Website">
                info@cstflightservices.com
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} style={{ justifyContent: "center" }}>
          <Grid
            container
            justify={"center"}
            className="policyTerms hidden_footer_b"
            direction="row"
            style={{ width: "100%" }}
          >
            <Grid
              style={{ padding: "15px", textAlign: "center" }}
              item
              xs={12}
              md={10}
            >
              <h6 style={{ color: "#FFF" }}>©2020 CST Flight Services</h6>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InternetExplorerDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  a {
    color: #fff;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      color: #001a41;
    }
  }
  .pAcontainer {
    margin: 0px;
  }
`;

// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
// `;
