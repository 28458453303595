import styled from 'styled-components';
import leftArrowImage from './Images/arrowLeft.png';
import rightArrowImage from './Images/arrowRight.png';


export const MainTestimonialContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`

export const TestimonialsHeaders = styled.h3`
  color: #561651;
  line-height: 1.6rem;
  font-style: italic;
`

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1rem;
`

export const LeftArrow = styled.div`
  background-image: url(${leftArrowImage});
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  border: 0.15rem solid #6a84a1;
  border-radius: 10000px;
  cursor: pointer;
`
export const RightArrow = styled.div`
  background-image: url(${rightArrowImage});
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  border: 0.15rem solid #6a84a1;
  padding: 0.26rem 0.24rem 0.22rem 0.24rem;
  border-radius: 10000px;
  cursor: pointer;
`