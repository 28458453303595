import styled from 'styled-components';

export const SearchBarContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: 'row';
    height: 30px
`

export const SearchInputContainer = styled.div`
    width: 100%;
    border-radius: 6px 0 0 6px;
    border-right: ${props => (props.screenType === 'REGULAR_SIZE' || props.searchInputVisible) ? 'none' : ''};
    background-color: #f0f0f0;
    display: flex;
    flex-direction: 'row';
    display: ${props => (props.screenType === 'REGULAR_SIZE' || props.searchInputVisible) ? '' : 'none'};
`
export const SearchInput = styled.input`
    background-color: none;
    border: none;
    border-radius: 6px;
    color: black;
    flex:1;
    text-align:left;
    text-justify: center;
`
export const SearchButton = styled.div`
    width: 30px;
    border: solid 2px #797783;
    /* border-left: ${props => (props.screenType === 'REGULAR_SIZE' || props.searchInputVisible) ? 'none' : ''}; */
    border-radius: ${props => (props.screenType === 'REGULAR_SIZE' || props.searchInputVisible) ? '0 6px 6px 0' : '6px'};
    background-color: ${props => props.clickedStyle ? '#D0D0D0' : '#f0f0f0'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`