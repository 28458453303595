import React from 'react';
import {Ballon,ComillasAbren,ComillasCierran,Person,Testimony} from './TestimonialsBalonStyles'

const TestimonialsBalon = ({text,author}) => {

  return <>
    <Ballon>
      <ComillasAbren>“</ComillasAbren>
      <Testimony>{text}</Testimony>
      <ComillasCierran>”</ComillasCierran>
    </Ballon>
    <Person>{author}</Person>
  </>;
};

export default TestimonialsBalon;