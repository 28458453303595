import React, { useState, useEffect } from "react";
import { ReactComponent as Begining } from "./icons/begining2.svg";
import { ReactComponent as Business } from "./icons/business.svg";
import { ReactComponent as Expanding } from "./icons/expanding.svg";
import { ReactComponent as Expeditions } from "./icons/expeditions.svg";
import { ReactComponent as Gathering } from "./icons/gathering.svg";
import { ReactComponent as Government } from "./icons/government.svg";
import { ReactComponent as Permits } from "./icons/permits.svg";
import { ReactComponent as PilotsGuide } from "./icons/pilotsGuide.svg";
import { ReactComponent as Today } from "./icons/today.svg";
import logosEvolution from "../../Images/logos-centro.jpg";
import Bar from "./components/Bar.js";
import Card from "./components/Card.js";

function Timeline() {
  const [appWidth, setAppWidth] = useState(
    document.documentElement.clientWidth
  );
  const [minElementWidth, setMinElementWidth] = useState(
    document.documentElement.clientWidth > 760 ? appWidth / 11 : appWidth / 3
  );

  const handleResize = () => {
    setAppWidth(document.documentElement.clientWidth);
    setMinElementWidth(
      document.documentElement.clientWidth > 760 ? appWidth / 11 : appWidth / 3
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [eventOpened, setOpenedEvent] = useState(-1);
  const [currentEvent, setCurrentEvent] = useState({});

  var barStyleOptions = {
    backgroundColor: "#FFF",
    placeholderColor: "#6583a6",
    eventCompletedColor: "#321130",
    eventOnStatusColor: "#321130",
    expandedColor: "#eef1f0",
    fontColor: "white",
    barWidth: { large: appWidth },
    eventWidth: minElementWidth,
    elementCount: 9
  };

  const clickHandler = (eventId, icon, child) => {
    setOpenedEvent(eventId);
    setCurrentEvent({
      icon: icon,
      content: child,
      marginLeft: (eventId + 1) * 3
    });
  };

  const Icon = currentEvent.icon && currentEvent.icon;

  const cardInfo = (title, text) => {
    return (
      <>
        <h2 className="cardInfoH2">{title}</h2>
        <p className="cardInfoP">{text}</p>
      </>
    );
  };

  const cards = (
    <>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="THE "
        date="BEGINNING"
        color="#7699c2"
        expandedHeight={180}
        icon={Begining}
        id={0}
        opened={eventOpened === 0}
        eventClick={clickHandler}
      >
        {cardInfo(
          "THE BEGINNING",
          `Caribbean Sky Tours was founded in 2004 in Cancun, Mexico and
          in Miami, Florida, USA. The original business model was to
          provide Air Taxi services in Mexico’s Yucatan Peninsula.`
        )}
      </Card>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="GATHERING"
        date="INFO"
        color="#6583A6"
        expandedHeight={160}
        icon={Gathering}
        id={1}
        opened={eventOpened === 1}
        eventClick={clickHandler}
      >
        {cardInfo(
          "GATHERING INFO",
          `In order to ensure that their pilots had reliable information, the founders flew to all airports in their region gathering technical and destination information.`
        )}
      </Card>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="PILOT'S "
        date="GUIDE"
        color="#4A75A1"
        expandedHeight={160}
        icon={PilotsGuide}
        id={2}
        opened={eventOpened === 2}
        eventClick={clickHandler}
      >
        {cardInfo(
          "PILOT'S GUIDE",
          `Realizing that this airport and destination information could be of value to visiting General Aviation pilots, The Pilot’s Guide to Mexico was created which evolved to include Central America as well.
          `
        )}
      </Card>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="INTL. "
        date="PERMITS"
        color="#3F658A"
        expandedHeight={160}
        icon={Permits}
        id={3}
        opened={eventOpened === 3}
        eventClick={clickHandler}
      >
        {cardInfo(
          "INTERNATIONAL PERMITS",
          `In addition to the Pilot Guide, the company began providing services to General Aviation pilots in need of assistance with processing Overflight and Landing Permits to Mexico and Central America.`
        )}
      </Card>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="GUIDED "
        date="EXPEDITIONS"
        color="#325373"
        expandedHeight={160}
        icon={Expeditions}
        id={4}
        opened={eventOpened === 4}
        eventClick={clickHandler}
      >
        {cardInfo(
          "GUIDED EXPEDITIONS",
          `The company  organized fully escorted Guided Expeditions. These allowed groups of pilots to fly their aircraft and their families on pre-arranged trips to visit fascinating destinations. 
          `
        )}
      </Card>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="GOVT. "
        date="LOBBYING"
        color="#325373"
        expandedHeight={190}
        icon={Government}
        id={5}
        opened={eventOpened === 5}
        eventClick={clickHandler}
      >
        {cardInfo(
          "GOVERNMENTAL LOBBYING & REPRESENTATION",
          `Using its knowledge and experience, the company began to lobby Civil Aviation Departments of multiple countries and to join their General Aviation councils. In addition, the company also represents AOPA’s interests in Mexico, Central America and The Bahamas.`
        )}
      </Card>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="EXPANDING "
        date="SERVICES"
        color="#1E4566"
        expandedHeight={160}
        icon={Expanding}
        id={6}
        opened={eventOpened === 6}
        eventClick={clickHandler}
      >
        {cardInfo(
          "EXPANDING SERVICES",
          `As customers traded up to larger and more capable aircraft, they requested additional services in an ever growing geographic territory. `
        )}
      </Card>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="BUSINESS "
        date="AVIATION"
        color="#1E4566"
        expandedHeight={160}
        icon={Business}
        id={7}
        opened={eventOpened === 7}
        eventClick={clickHandler}
      >
        {cardInfo(
          "BUSINESS AVIATION",
          `The range of services and geographic coverage caught the attention of the Business Aviation market and that of a major Flight Planning company who have all benefited from our services over the years. `
        )}
      </Card>
      <Card
        styleOptions={barStyleOptions}
        isOnStatus
        title="TODAY"
        date={""}
        color="#1e4566"
        expandedHeight={160}
        icon={Today}
        id={8}
        opened={eventOpened === 8}
        eventClick={clickHandler}
      >
        {cardInfo(
          "TODAY",
          `The company offers a wide range of services to cover the needs of both owner-pilot and professional pilots flying piston engine aircraft to business jets.`
        )}
      </Card>
    </>
  );

  return (
    <>
      <div className="parent" id="parentOverhaul">
        <div id="overhaul">
          <p id="overhaulP">
            OUR COMPANY HAS EVOLVED, SO WE DECIDED TO DO A LITTLE OVERHAUL!
          </p>
        </div>
        <div id="overhaulText">
          <p id="overhaulPText">
            You have formerly known us as Caribbean Sky Tours and while our
            image is changing, our services will continue to have the same high
            level of quality and customized attention.
            <br />
            <br />
            Whether you are an owner-pilot or a corporate pilot, flying a small
            aircraft or a large jet, you will find all the information and
            services that you need right here. As always, you can count on the
            assistance of our Customer Service Department or our Flight
            Coordinators.
          </p>
        </div>
      </div>
      <div id="logosEvolution">
        <img
          src={logosEvolution}
          alt="Company evolution"
          id="logoTransformation"
        />
      </div>
      <div id="timelineNotMobile" style={{ margin: "40px auto" }}>
        <Bar
          styleOptions={barStyleOptions}
          title="THE STORY BEHIND CST FLIGHT SERVICES"
          detail={
            appWidth >= 760
              ? "Hover on each tab to view the information"
              : "Click on each tab to view the information and swipe right to view all tabs."
          }
          timelineEvents={cards}
        />
        <div class="scrollEventsContainer">
          <div id="eventsContainer" style={{ width: minElementWidth * 11 }}>
            <svg className="events" overflow="visible">
              {cards}
            </svg>
          </div>
        </div>
        {eventOpened >= 0 && (
          <div
            id="content-container"
            style={{ marginLeft: `${currentEvent.marginLeft}%` }}
          >
            <Icon id="detailedIcon" />
            {currentEvent.content}
          </div>
        )}
      </div>
    </>
  );
}

export default Timeline;
