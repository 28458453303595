import React, { useState, useEffect } from 'react';
import { SearchBarContainer, SearchInput, SearchButton, SearchInputContainer } from './SearchBarStyle';
import SearchIcon from '@material-ui/icons/Search';

const REGULAR_SIZE = 'REGULAR_SIZE';
const MOBILE_SIZE = 'MOBILE_SIZE';

const SearchBar = props => {

    const [clicked, setClicked] = useState(false);
    const [screenType, setScreenType] = useState(() => { console.log("asign"); return REGULAR_SIZE; });
    const [searchInputVisible, setSearchInputVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    const handleSearch = () => {
        console.log("Realizadon busqueda...");
        if (screenType === REGULAR_SIZE) {
            document.forms['search-form'].submit();
        } else {
            if (searchText) {
                document.forms['search-form'].submit();
            } else {
                setSearchInputVisible(true);
            }
        }
    };

    const onFocusLost = () => {
        if (!searchText) {
            setSearchInputVisible(false);
        }
    }

    useEffect(() => {
        const resize = () => {
            console.log("Resize!", screenType);
            console.log(window.innerWidth)
            setScreenType(window.innerWidth > 760 ? REGULAR_SIZE : MOBILE_SIZE);
        }
        window.addEventListener('resize', resize);
        resize();
    }, [screenType]);

    return <form name="search-form" action="http://www.cstflightservices.com/Search-Results.html">
        <SearchBarContainer>
            <SearchInputContainer screenType={screenType} searchInputVisible={searchInputVisible}>
                <input value="011385682363034735105:uriirocmfce" name="cx" type="hidden" />
                <input value="FORID:11" name="cof" type="hidden" />
                <SearchInput name='q' type='text' placeholder='Search...' onChange={event => setSearchText(event.target.value)} onBlur={onFocusLost} />
            </SearchInputContainer>
            <SearchButton type='submit' onClick={handleSearch} onMouseUp={() => setClicked(false)} onMouseDown={() => setClicked(true)} clickedStyle={clicked} screenType={screenType} searchInputVisible={searchInputVisible}>
                <SearchIcon />
            </SearchButton>
        </SearchBarContainer>
    </form>

}

export default SearchBar;