import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import firebase from './firebase/firebase.js';
import TestimonialsBalon from './components/TestimonialsBalon/TestimonialsBalon';
import GaNewsBoard from './components/GaNewsBoard/GaNewsBoard';
import { MainTestimonialContainer, TestimonialsHeaders } from './styledGaNews';


const _mockData = [
  {
    text: "This was the first time our company had done anything like this. We go to the Bahamas all the time, but that has a lot less paperwork stuff, so it was definitely a learning process for all of us. I think there will be more trips down there, so we will definitely be giving you guys a call again when that happens.",
    author: "Frank Underwood",
  },
  {
    text: "I cannot thank you and your staff enough on the tremendous job given the time constraints! Our trip went off great and everything was in order.\n \n We will definitely use you on our next international trip... which is soon.\n \nThanks Again",
    author: "Paul Chmiel",
  },
]

const GaNews = props => {

  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  const [mockData, setMockData] = useState([]);
  const [app, setApp] = useState(null);
  const [db, setDB] = useState(null);

  useEffect(() => {
    //FETCH DIAGNOSES
    if (!app) {
      try {
        setApp(firebase);
      } catch (error) {
        console.error("useEffect: ", error);
      }
    } else {
      setDB(app.firestore());
    }

    if (db) {
      db.collection("ganews")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No se encontraron eventos");
          } else {
            const result = [];
            querySnapshot.forEach(doc => {
              const resultado = {_id: doc.id,...doc.data()};
              console.log(resultado);
              result.push(resultado);
            });
            setMockData(result);
          }
        });
    }
  }, [db, app]);


  return <MainTestimonialContainer>
    <TestimonialsHeaders>
      Read about the lastest GA News...
    </TestimonialsHeaders>
    {mockData.length ? <GaNewsBoard 
      title={mockData[0].title}
      text={mockData[0].text} 
      readMoreLink={mockData[0].readMoreLink}/>:null
    }
  </MainTestimonialContainer>;
};

export default GaNews;