import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import styled from "styled-components";
import moment from "moment";

import "./main.scss"; // webpack must be configured to do this

const CustomizedCalendar = (props) => {
  const { events, eventClick } = props;
  const [formattedEvents, setFormattedEvents] = useState([]);

  useEffect(() => {
    if (events.length > 0) {
      setFormattedEvents(
        events.map((event) => {
          const newEnd =
            event.end && moment(event.end).add(1, "day").toISOString();
          return { ...event, end: newEnd || null };
        })
      );
    }
  }, [events]);

  return (
    <Wrapper>
      <FullCalendar
        defaultView="dayGridMonth"
        plugins={[dayGridPlugin]}
        events={formattedEvents}
        eventClick={eventClick}
      />
    </Wrapper>
  );
};

export default CustomizedCalendar;

const Wrapper = styled.div`
  .fc-time {
    display: none;
  }
`;
