import React from "react";

const Card = (props) => {
  const { styleOptions, color, id, eventClick, opened, children, icon } = props;
  const xFactorDtl = styleOptions.eventWidth + 14;
  const x = id * xFactorDtl;
  const Icon = icon;
  let xOffset = styleOptions.barWidth.large > 760 ? 50 : 0;
  const isMobile = styleOptions.barWidth.large <= 760;
  const isTablet = styleOptions.barWidth.large <= 860;

  const circledIcon = (
    <g className="icon-move">
      <g className="icon-group" transform={`translate(${x + 12 + xOffset}, 8)`}>
        <path
          className="icon-shape"
          id={`cir-${id}`}
          d="M0,25 a25,25,0,0,1,50,0 a25,25,0,0,1,-50,0"
          fill={styleOptions.eventOnStatusColor}
          stroke={styleOptions.backgroundColor}
          style={{ strokeOpacity: 1 }}
          strokeMiterlimit="10"
          strokeWidth="2"
        ></path>
        <Icon className="icon-svg" fill="#d09f53" x="0" y="0" fontSize="28" />
      </g>
    </g>
  );

  const downArrow = (
    <g
      className="up-icon"
      onMouseEnter={() => {
        !isTablet && eventClick(id, Icon, children);
      }}
      onMouseLeave={() => {
        !isTablet && eventClick(-1, undefined, undefined);
      }}
      onClick={() => {
        if (opened) {
          eventClick(-1, undefined, undefined);
        } else {
          eventClick(id, Icon, children);
        }
      }}
      cursor="pointer"
      visibility="visible"
      transform={`translate(${x + 40 + xOffset}, 8)`}
    >
      <path
        className="up-shape"
        opacity="0"
        id={`cir-${props.id}`}
        d={`M0,20 h${styleOptions.eventWidth - 20}  v70 h-${
          styleOptions.eventWidth - 20
        } v-70`}
        transform="translate(2,2)"
        fill="#4a75a1"
        stroke={styleOptions.backgroundColor}
        strokeMiterlimit="10"
        strokeWidth="0"
      ></path>
      <svg
        stroke="currentColor"
        fill="#d09f53"
        strokeWidth="0"
        viewBox="0 0 512 512"
        className="icon-svg"
        x="0"
        y="48"
        fontSize="28"
        height="25px"
        width="90px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273
                  167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4
                  24.6 9.4 33.9 0l127.1-127z"
          transform={` ${
            !opened
              ? "rotate(180) translate(-750, -600)"
              : "translate(150, 150)"
          }`}
        ></path>
      </svg>
    </g>
  );

  return (
    <g
      className="top-element-node"
      id={`event-${id}`}
      ref={props.setRef}
      cursor="pointer"
      visibility={opened ? "hidden" : "visible"}
      display="block"
      onClick={() => {
        if (!props.opened) {
          eventClick(id, Icon, children);
        }
      }}
    >
      <g className="masked">
        <g className="tag-move">
          <path
            className="tag"
            id={`rect-${id}`}
            d={`M6,0 h${styleOptions.eventWidth} h0 h0 a6,6,0,0,1,6,6 l10, 28 l-10, 28 a6,6,0,0,1,-6,6 h-${styleOptions.eventWidth} a6,6,0,0,1,-6,-6 l10, -28 l-10, -28 a6,6,0,0,1,6,-6`}
            transform={`translate(${x + 20 + xOffset}, 20)`}
            fill={color}
          />
          <text
            className="title"
            fontFamily="Verdana"
            fontSize={isMobile ? "8" : "10"}
            fontWeight="bold"
            fill={styleOptions.fontColor}
            transform={`translate(${x + 70 + xOffset}, 40)`}
          >
            {props.title}
          </text>
          <text
            className="date"
            fontFamily="Verdana"
            fontSize={isMobile ? "8" : "10"}
            fontWeight="bold"
            fill={styleOptions.fontColor}
            transform={`translate(${x + 65 + xOffset}, 58)`}
          >
            {props.date}
          </text>
        </g>
      </g>
      {circledIcon}
      {downArrow}
    </g>
  );
};

export default Card;
