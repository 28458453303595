import styled from 'styled-components';


export const Ballon = styled.div`
  border-radius: 0.3rem;
  background-color: #eff0f2;
  margin: 0 0.6rem 0 0.3rem;
  position: relative;
  display: block;
`

export const NewsText = styled.h6`
  font-size: 0.95rem;
  line-height: 1.25rem;
  padding: 0.8rem 0.6rem 0.6rem 0.8rem;
`

export const NewsTitle = styled.h6`
  color: #561651;
  text-align: center;
  font-weight: bold;
  line-height: 1.5rem;
  font-size: 1.15rem;
  margin-bottom: 0.5rem;
  cursor: text;
  margin: 12px;
  font-family: "Cambay", sans-serif;
`

export const NewsReadMore = styled.a`
  color: #6a84a1;
  text-decoration: underline;
  line-height: inherit;
  font-size: 0.95rem;
`