import React, { useEffect, useState } from "react";
//import { Wrapper } from "./styledEvents.js";
import Calendar from "./components/FullCalendar/FullCalendar.js";
import firebase from "./firebase/firebase.js";
import moment from "moment";
import { Grid, Divider, makeStyles } from "@material-ui/core";
import ButtonImage from "./main_yellow_button.jpg";

const useStyles = makeStyles((theme) => ({
  oldFashion: {
    fontSize: "0.9rem",
    padding: "0.4rem 1rem 0.4rem 1rem",
    backgroundRepeat: "repeat-x",
    backgroundSize: "auto 100%",
    color: "#FFFFFF",
    fontWeight: "bold",
    border: "0.15rem solid #C4C4C3",
    boxShadow:
      "0.01rem -0.01rem 0.2rem #555555 inset, 0.04rem 0.04rem 0.3rem 0.06rem rgba(0, 0, 0, 0.9)",
    margin: "1rem",
    backgroundImage: `url(${ButtonImage})`,
    "&:hover": {
      cursor: "pointer"
    }
  },
  newFashion: {
    fontSize: "0.9rem",
    padding: "10px 15px",
    color: "#FFFFFF",
    fontWeight: "bold",
    backgroundColor: "#561651",
    borderRadius: "5px",
    margin: "1rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#321130"
    }
  }
}));

function ComingEvents() {
  //VARIABLES
  const [db, setDB] = useState(null);
  const [app, setApp] = useState(null);
  const [cstEvents, setEvents] = useState([]);
  const [cE, setClickedEvent] = useState(null);
  const classes = useStyles();

  //Event click handler
  const eventClick = (info) => {
    info.jsEvent.preventDefault(); // don't let the browser navigate
    console.log(info.event);
    setClickedEvent(info.event);
  };

  useEffect(() => {
    let isMounted = true;

    //FETCH DIAGNOSES
    if (!app) {
      try {
        setApp(firebase);
      } catch (error) {
        console.error("useEffect: ", error);
      }
    } else {
      setDB(app.firestore());
    }

    if (db) {
      db.collection("events")
        .get()
        .then((querySnapshot) => {
          if (isMounted) {
            if (querySnapshot.empty) {
              console.log("No se encontraron eventos");
            } else {
              let tempRows = [];
              querySnapshot.forEach((doc) => {
                tempRows.push({
                  _id: doc.id,
                  ...doc.data()
                });
              });
              setEvents(tempRows);
              //console.log(tempRows);
            }
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [db, app]);

  return (
    <Grid container direction="row" alignItems="center" justify="center">
      {cE && (
        <Grid item xs={false} lg={4} style={{ padding: "20px" }}>
          <div
            style={{
              backgroundColor: cE.backgroundColor && cE.backgroundColor,
              color: "#FFF",
              width: "90%",
              padding: "10px 20px",
              margin: "auto",
              marginBottom: "25px",
              height: "auto"
            }}
          >
            <h3
              style={{
                color: "#FFF",
                margin: "0px",
                fontSize: "25px",
                fontStyle: "normal",
                textAlign: "center"
              }}
            >
              EVENT INFORMATION
            </h3>
          </div>

          <div
            style={{
              backgroundColor: "#EEF1F0",
              color: "#001A41",
              width: "90%",
              padding: "10px",
              margin: "auto",
              borderRadius: "10px",
              textAlign: "justify"
            }}
          >
            <div
              style={{
                color: cE.backgroundColor && cE.backgroundColor,
                padding: "25px",
                fontSize: "22px",
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              {cE.title && cE.title}
            </div>
            <Divider />
            <div
              style={{
                padding: "20px 20px 20px 30px",
                fontSize: "20px"
              }}
            >
              <strong>Dates: </strong>
              {`${cE.start && moment(cE.start).format("DD MMM, YYYY")} ${
                cE.end
                  && moment(cE.end).subtract(1, "day").format(" - MMM DD, YYYY")
              }`}
            </div>
            <div
              style={{
                padding: "0px 20px 20px 30px",
                fontSize: "20px"
              }}
            >
              {cE.extendedProps && cE.extendedProps.place && (
                <strong>Place: </strong>
              )}
              {`${(cE.extendedProps && cE.extendedProps.place) || ""}`}
            </div>
            <div
              style={{
                padding: "0px 20px 20px 30px",
                fontSize: "20px"
              }}
            >
              {cE.extendedProps && cE.extendedProps.details && (
                <strong>Details: </strong>
              )}

              {`${(cE.extendedProps && cE.extendedProps.details) || ""}`}
            </div>
            <Divider />
            <div
              style={{
                padding: "20px",
                fontSize: "20px",
                textAlign: "center"
              }}
            >
              {cE.extendedProps && cE.extendedProps.type !== "expedition" && (
                <h3
                  style={{
                    color: "#001A41",
                    fontStyle: "normal",
                    fontSize: "1.3rem"
                  }}
                >
                  Request an appointment with one of our representatives
                </h3>
              )}
              {cE.extendedProps && cE.extendedProps.formUrl && (
                <button
                  className={classes.newFashion}
                  onClick={() => window.open(cE.extendedProps.formUrl)}
                >
                  {cE.extendedProps.type === "expedition"
                    ? "MORE INFO"
                    : "APPOINTMENT"}
                </button>
              )}
              {cE.extendedProps && cE.extendedProps.button && (
                <button
                  className={classes.newFashion}
                  onClick={() => window.open(cE.extendedProps.button)}
                >
                  {cE.extendedProps.type === "expedition"
                    ? "MORE INFORMATION"
                    : "APPOINTMENT"}
                </button>
              )}
            </div>
          </div>
        </Grid>
      )}
      <Grid item xs={12} lg={7} style={{ padding: "20px" }}>
        <Calendar events={cstEvents || []} eventClick={eventClick} />
      </Grid>
    </Grid>
  );
}

export default ComingEvents;
