import React, { useEffect, useState } from "react";
import firebase from "./firebase/firebase.js";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Warning from "./Warning";
// import ImagesCarousel from "./components/ImagesCarousel.js";
import moment from "moment";
import ButtonImage from "./main_yellow_button.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: "18px",
    flexBasis: "80%",
    flexShrink: 0,
    color: "#001A41",
    fontWeight: "bold"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  oldFashion: {
    fontSize: "0.9rem",
    padding: "0.4rem 1rem 0.4rem 1rem",
    backgroundRepeat: "repeat-x",
    backgroundSize: "auto 100%",
    color: "#FFFFFF",
    fontWeight: "bold",
    border: "0.15rem solid #C4C4C3",
    boxShadow:
      "0.01rem -0.01rem 0.2rem #555555 inset, 0.04rem 0.04rem 0.3rem 0.06rem rgba(0, 0, 0, 0.9)",
    margin: "1rem",
    backgroundImage: `url(${ButtonImage})`,
    "&:hover": {
      cursor: "pointer"
    }
  },
  newFashion: {
    fontSize: "0.9rem",
    padding: "10px 15px",
    color: "#FFFFFF",
    fontWeight: "bold",
    backgroundColor: "#561651",
    borderRadius: "5px",
    margin: "1rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#321130"
    }
  }
}));

function Experiences() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState({});
  const [expandedPast, setPastExpanded] = useState(false);
  const [db, setDB] = useState(null);
  const [app, setApp] = useState(null);
  const [cstExpeditions, setExpeditions] = useState([]);
  const [cstPastExpeditions, setPastExpeditions] = useState([]);

  const handleChange = (panel, past) => (event, isExpanded) => {
    if (past) {
      setPastExpanded(isExpanded ? panel : false);
    } else {
      setExpanded((currState) =>
        isExpanded
          ? { ...currState, [panel]: true }
          : { ...currState, [panel]: false }
      );
    }
  };

  useEffect(() => {
    //FETCH DIAGNOSES
    if (!app) {
      try {
        setApp(firebase);
      } catch (error) {
        console.error("useEffect: ", error);
      }
    } else {
      setDB(app.firestore());
    }

    if (db) {
      db.collection("events")
        .where("type", "==", "expedition")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No se encontraron eventos");
          } else {
            let tempRows = [];
            let tempPastRows = [];

            querySnapshot.forEach((doc) => {
              const startDate = doc?.data()?.start;
              if (!startDate || !moment().isBefore(startDate)) {
                tempPastRows.push({
                  _id: doc.id,
                  ...doc.data()
                });
              } else {
                tempRows.push({
                  _id: doc.id,
                  ...doc.data()
                });
              }
            });

            tempRows.sort(function (a, b) {
              const temp1 = new Date(a.start);
              const temp2 = new Date(b.start);
              if (temp1 < temp2) {
                return -1;
              }
              if (temp2 > temp1) {
                return 1;
              }
              return 0;
            });
            tempPastRows.sort(function (a, b) {
              const temp1 = new Date(a.start);
              const temp2 = new Date(b.start);
              if (temp1 >= temp2) {
                return -1;
              }
              if (temp2 < temp1) {
                return 1;
              }
              return 0;
            });
            setExpeditions(tempRows, false);
            setPastExpeditions(tempPastRows, true);
            console.log(tempRows);
            tempPastRows.length > 0 && setPastExpanded(tempPastRows[0]._id);
            const tempRowsExpanded = tempRows.reduce((acc, curr) => {
              return { ...acc, [curr._id]: true };
            }, {});
            tempRows.length > 0 && setExpanded(tempRowsExpanded);
          }
        });
    }
  }, [db, app]);

  const expandedPanels = cstExpeditions.map((exp) => {
    return (
      <Grid
        item
        xs={12}
        key={exp._id}
        style={{ margin: "10px 0px", width: "100%" }}
      >
        <Accordion
          expanded={Object.keys(expanded).length > 0 ? expanded[exp._id] : true} //JUST FIRST ONE IS EXPANDED
          //expanded //ALL OF THEM ARE EXPANDED
          onChange={handleChange(exp._id, false)}
          style={{ borderRadius: "10px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              backgroundColor: "#DDDEE0",
              borderRadius: "10px 10px 0px 0px"
            }}
          >
            <Typography className={classes.heading}>{exp.title}</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              backgroundColor: "#DDDEE0",
              borderRadius: "0px 0px 10px 10px",
              paddingBottom: "0px"
            }}
          >
            <Grid
              container
              alignItems="center"
              justify="space-between"
              direction="row"
            >
              <Grid item xs={12} lg={3}>
                <img
                  alt="mainImage"
                  src={exp.photos[0]}
                  style={{ maxWidth: "100%" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                style={{
                  padding: "15px",
                  fontSize: "15px",
                  color: "#001A41",
                  textAlign: "justify"
                }}
              >
                <Typography>{exp.description || ""}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={3}
                style={{
                  padding: "5px 15px 5px 25px",
                  fontSize: "15px",
                  color: "#001A41",
                  borderLeft: "solid 2px #001A41",
                  marginBottom: "10px"
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    marginBottom: "5px"
                  }}
                >
                  <strong>Dates: </strong>
                </p>
                <p>
                  {exp.postponed ? 'Postponed' : `${exp.start && moment(exp.start).format("MMM DD, YYYY")} ${exp.end && moment(exp.end).format(" - MMM DD, YYYY")}`}
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    marginBottom: "5px"
                  }}
                >
                  {exp.placesToVisit && <strong>Landmarks: </strong>}
                </p>
                <ul>
                  {exp.placesToVisit?.map((place, index) => {
                    return <li key={place + index}>{place}</li>;
                  })}
                </ul>
                {exp.button && (
                  <button
                    className={classes.newFashion}
                    onClick={() => window.open(exp.button)}
                  >
                    MORE INFORMATION
                  </button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
          {/* <ImagesCarousel images={exp.photos} /> */}
        </Accordion>
      </Grid>
    );
  });

  const pastExpandedPanels = cstPastExpeditions.map((exp) => {
    return (
      <Grid item xs={12} key={exp._id} style={{ margin: "10px 0px" }}>
        <Accordion
          expanded={expandedPast === exp._id}
          onChange={handleChange(exp._id, true)}
          style={{ borderRadius: "10px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              backgroundColor: "#DDDEE0",
              borderRadius: "10px 10px 0px 0px"
            }}
          >
            <Typography className={classes.heading}>{exp.title}</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              backgroundColor: "#DDDEE0",
              borderRadius: "0px 0px 10px 10px"
            }}
          >
            <Grid
              container
              alignItems="center"
              justify="space-between"
              direction="row"
            >
              <Grid item xs={12} lg={3}>
                <img
                  alt="mainImage"
                  src={exp.photos[0]}
                  style={{ maxWidth: "100%" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                style={{
                  padding: "15px",
                  fontSize: "15px",
                  color: "#001A41",
                  textAlign: "justify"
                }}
              >
                <Typography>{exp.description || ""}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={3}
                style={{
                  padding: "15px 15px 15px 25px",
                  fontSize: "15px",
                  color: "#001A41",
                  borderLeft: "solid 2px #001A41"
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    marginBottom: "5px"
                  }}
                >
                  <strong>Dates: </strong>
                  {/* {`${exp.start && moment(exp.start).format("YYYY")}`} */}
                </p>
                <p>
                  {`${exp.start && moment(exp.start).format("MMM DD, YYYY")} ${ exp.end && moment(exp.end).format("- MMMM DD, YYYY")}`}
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    marginBottom: "5px"
                  }}
                >
                  {exp.placesToVisit && <strong>Landmarks: </strong>}
                </p>
                <ul>
                  {exp.placesToVisit?.map((place, index) => {
                    return <li key={place + index}>{place}</li>;
                  })}
                </ul>
                {exp.button && (
                  <button
                    className={classes.newFashion}
                    onClick={() => window.open(exp.button)}
                  >
                    MORE INFORMATION
                  </button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
          {/* <ImagesCarousel images={exp.photos} /> */}
        </Accordion>
      </Grid>
    );
  });

  return (
    <Grid
      container
      alignItems="center"
      justify="space-around"
      direction="column"
      style={{ margin: "0px, 10px, 0px, 10px", padding: "10px" }}
    >
      <Grid item xs={12} md={8} style={{ marginTop: "20px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <p
            style={{
              color: "#561651",
              fontSize: "15px",
              fontStyle: "normal",
              maxWidth: "90%",
              margin: "0px"
            }}
          >
            Home <span style={{ color: "#D09F53" }}> {">>"} </span>{" "}
            <span style={{ color: "#001a41" }}>CST Guided expeditions</span>
          </p>
        </div>
      </Grid>
      {/* <Grid item xs={12} md={8} style={{ marginTop: "20px", width: "100%" }}>
        <Warning/>
      </Grid> */}
      <Grid item xs={12} md={8} style={{ marginTop: "20px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <h3
            style={{
              color: "#001A41",
              fontSize: "20px",
              fontStyle: "normal",
              maxWidth: "80%",
              margin: "0px"
            }}
          >
            GUIDED EXPEDITIONS
          </h3>
          <hr
            style={{
              borderTop: "solid 2px #D09F53",
              flex: "auto",
              marginLeft: "20px"
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={8} style={{ marginTop: "0px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <h3
            style={{
              color: "#561651",
              fontSize: "18px",
              maxWidth: "80%",
              margin: "0px"
            }}
          >
            Unique flying expeditions with fellow pilots, guided and accompanied
            by CST Flight Services
          </h3>
        </div>
      </Grid>

      <Grid item xs={12} md={8} style={{ marginTop: "20px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#561651",
            padding: "10px",
            marginBottom: "10px"
          }}
        >
          <h3
            style={{
              color: "#FFFFFF",
              fontSize: "20px",
              maxWidth: "50%",
              margin: "0px",
              fontStyle: "normal"
            }}
          >
            UPCOMING EXPEDITIONS:
          </h3>
        </div>
      </Grid>
      <Grid item xs={12} md={8} style={{ width: "100%" }}>
        <Grid
          container
          alignItems="center"
          justify="space-around"
          direction="column"
        >
          {expandedPanels}
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} style={{ marginTop: "20px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#561651",
            padding: "10px",
            marginBottom: "10px"
          }}
        >
          <h3
            style={{
              color: "#FFFFFF",
              fontSize: "20px",
              maxWidth: "50%",
              margin: "0px",
              fontStyle: "normal"
            }}
          >
            PAST EXPEDITIONS:
          </h3>
        </div>
      </Grid>
      <Grid item xs={12} md={8} style={{ marginTop: "20px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <h3
            style={{
              color: "#561651",
              fontSize: "18px",
              maxWidth: "80%",
              margin: "0px"
            }}
          >
            Searching for inspiration on places to visit? Take a look into some
            of our past expeditions
          </h3>
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid
          container
          alignItems="center"
          justify="space-around"
          direction="column"
        >
          {pastExpandedPanels}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Experiences;
