import styled from 'styled-components';


export const Ballon = styled.div`
  border-radius: 0.3rem;
  background-color: #eff0f2;
  margin: 0 0.6rem 0 0.3rem;
  position: relative;
  display: block;
  :before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 2.4rem;
    right: auto;
    top: auto;
    bottom: -1.3rem;
    border: 0.75rem solid;
    border-color: #eff0f2 transparent transparent #eff0f2;
  }
`

export const ComillasAbren = styled.div`
  font-size: 5rem;
  color: #561651;
  line-height: 0;
  float: left;
  top: 3.3rem;
  left: 0.55rem;
  position: relative;
`

export const Testimony = styled.h6`
  font-size: 0.95rem;
  line-height: 1.25rem;
  padding: 1.2rem 2.2rem 0.75rem 2.5rem;
`

export const ComillasCierran = styled.div`
  font-size: 5rem;
  color: #561651;
  line-height: 0;
  float: right;
  right: 0.6rem;
  position: relative;
`

export const Person = styled.div`
  color: #561651;
  font-size: 0.9rem;
  margin: 1.6rem 0 0 1rem;
  font-weight: bold;
`