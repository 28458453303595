import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import firebase from './firebase/firebase.js';
import TestimonialsBalon from './components/TestimonialsBalon/TestimonialsBalon';
import { ArrowContainer, LeftArrow, MainTestimonialContainer, RightArrow, TestimonialsHeaders } from './styledTestimonials';


const _mockData = [
  {
    text: "This was the first time our company had done anything like this. We go to the Bahamas all the time, but that has a lot less paperwork stuff, so it was definitely a learning process for all of us. I think there will be more trips down there, so we will definitely be giving you guys a call again when that happens.",
    author: "Frank Underwood",
  },
  {
    text: "I cannot thank you and your staff enough on the tremendous job given the time constraints! Our trip went off great and everything was in order.\n \n We will definitely use you on our next international trip... which is soon.\n \nThanks Again",
    author: "Paul Chmiel",
  },
]

const Testimonials = props => {

  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  const [mockData, setMockData] = useState([]);
  const [app, setApp] = useState(null);
  const [db, setDB] = useState(null);

  useEffect(() => {
    //FETCH DIAGNOSES
    if (!app) {
      try {
        setApp(firebase);
      } catch (error) {
        console.error("useEffect: ", error);
      }
    } else {
      setDB(app.firestore());
    }

    if (db) {
      db.collection("testimonials")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No se encontraron eventos");
          } else {
            const result = [];
            querySnapshot.forEach(doc => {
              const resultado = {_id: doc.id,...doc.data()};
              console.log(resultado);
              result.push(resultado);
            });
            setMockData(result);
            setSelectedTestimonial(Math.floor(Math.random() * result.length))
          }
        });
    }
  }, [db, app]);

  // useEffect(() => {
  //   setInterval(() => {
  //     setSelectedTestimonial(prev => {
  //       return prev+1 < mockData.length ? prev+1:0;
  //     })
  //   },13000)
  // },[]);

  const handleNext = () => {
    setSelectedTestimonial(prev => {
      return prev+1 < mockData.length ? prev+1:0;
    })
  }
  const handlePrev = () => {
    setSelectedTestimonial(prev => {
      const resultado = prev-1 >= 0 ? prev-1:mockData.length-1;
      return resultado;
    })
  }

  return <MainTestimonialContainer>
    <TestimonialsHeaders>
      What our customers are saying...
    </TestimonialsHeaders>
    {mockData.length ? <TestimonialsBalon text={mockData[selectedTestimonial].text} author={mockData[selectedTestimonial].author}/>:null}
    <ArrowContainer>
      <LeftArrow onClick={handlePrev}/>
      <RightArrow onClick={handleNext}/>
    </ArrowContainer>
  </MainTestimonialContainer>;
};

export default Testimonials