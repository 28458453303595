import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .CovidHeader {
    color: #c1320c;
    margin: 15px 0px;
  }
  .CovidMessage {
    width: 900px;
    max-width: 90vw;
    text-align: justify;
  }
  .CovidButton {
    background-color: #c1320c;
    border: 2px solid #c1320c;
    border-radius: 10px;
    color: white;
    padding: 10px 20px;
    font-size: 20px;
  }
`;
