import React from "react";

const Bar = (props) => {
  const { styleOptions } = props;
  const width = styleOptions.barWidth.large;
  const isMobile = styleOptions.barWidth.large <= 760;

  let xOffset = styleOptions.barWidth.large > 760 ? 35 : -30;
  let xOffset2 = styleOptions.barWidth.large > 760 ? 35 : 50;

  return (
    <svg
      className="proc-timeline-svg"
      id="tool-bar"
      xmlns="http://www.w3.org/2000/svg"
      width={styleOptions.barWidth.large}
      height="135px"
      visibility="visible"
      display="fixed"
      viewBox={`0 0 ${styleOptions.barWidth.large} 135`}
    >
      <g className="top-element-node" id={`bar`}>
        <path
          className="header-bar"
          id={`rect-`}
          d={`M0,0 h${width + 15 + xOffset2} a6,6,0,0,1,6,5 l5, 60 h-${
            width - xOffset
          } a8,8,0,0,0,-7,7 l-20, 57 a8,8,0,0,1,-6,6 h-59 a6,6,0,0,1,-6,-6 v-123 a6,6,0,0,1,6,-6`}
          transform={`translate(0, 0)`}
          fill="#6583a6"
        />
        <defs>
          <clipPath id="mask">
            <path
              className="event-clip"
              d={`M0,0 h${styleOptions.barWidth.large - 100} v68 h-${
                styleOptions.barWidth.large - 60
              } Z`}
              transform={`translate(165, 20)`}
              fill="black"
            />
          </clipPath>
        </defs>
        <text
          className="title"
          x="14"
          y="19"
          fontFamily="Verdana"
          fontSize={isMobile ? "10" : "12"}
          fontWeight="bold"
          fill={styleOptions.fontColor}
        >
          {props.title}
        </text>
        <text
          className="detail"
          x="14"
          y="36"
          fontFamily="Verdana"
          fontSize={isMobile ? "9" : "12"}
          fill={styleOptions.fontColor}
        >
          {props.detail}
        </text>
      </g>
    </svg>
  );
};

export default Bar;
