import React, { useState } from "react";
import styled from "styled-components";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";

const asteriskStyle = { color: "#d09f53" };

const ContactUs = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [robot, setRobot] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    if (robot) {
      const data = new FormData();

      data.append("name", name);
      data.append("phone", phoneNumber);
      data.append("email", email);
      data.append("message", message);

      axios({
        url: "https://www.cstflightservices.com/send-mail.php",
        method: "POST",
        headers: {
          "content-type": "multipart/form-data"
        },
        data: data
      })
        .then((response) => {
          console.log(JSON.stringify(response));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setName("");
          setNumber("");
          setEmail("");
          setMessage("");
          setRobot(false);
        });
    } else {
      alert("Please check the checkbox");
    }
  };

  return (
    <ContactContainer
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={8}
        lg={4}
        style={{
          backgroundColor: "#eef1f0",
          borderRadius: "10px",
          padding: "10px",
          width: "100%",
          marginBottom: "20px"
        }}
      >
        <h2 style={{ margin: "auto", textAlign: "center" }}>CONTACT US</h2>
        <form
          id="contact-form"
          className="contact"
          name="contact-form"
          onSubmit={submitForm}
        >
          <div id="FormContainer">
            <TextField
              className="textField"
              label={
                <p>
                  Name<span style={asteriskStyle}>*</span>
                </p>
              }
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              className="textField"
              label={
                <p>
                  Phone Number<span style={asteriskStyle}>*</span>
                </p>
              }
              name="phone"
              type="number"
              value={phoneNumber}
              onChange={(e) => setNumber(e.target.value)}
            />
            <TextField
              className="textField"
              label={
                <p>
                  Email Address<span style={asteriskStyle}>*</span>
                </p>
              }
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              className="textField"
              label={
                <p>
                  Message<span style={asteriskStyle}>*</span>
                </p>
              }
              name="message"
              multiline
              rowsMax="6"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  id="myCheckbox"
                  checked={robot}
                  onChange={() => setRobot((isRobot) => !isRobot)}
                  value={robot}
                />
              }
              label="I'm not a robot"
              id="checkboxLabel"
              labelPlacement="end"
            />
            <button id="reactFormButton" type="submit">
              Send
            </button>
          </div>
        </form>
      </Grid>
      <h3>US Phone: +1 786 206 6147</h3>
      <h3>MX Phone: +52 222 944 3402</h3>
      <h3>
        Email:{" "}
        <a href="mailto:info@cstflightservices.com">
          info@cstflightservices.com
        </a>
      </h3>
    </ContactContainer>
  );
};

export default ContactUs;

const ContactContainer = styled(Grid)`
  width: 100%;
  h3 {
    color: #001a41;
  }

  #FormContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px 10px 0px;
    .textField {
      margin-bottom: 10px;
    }

    .textField input {
      border: none;
      background-color: transparent;
      box-shadow: unset;
      margin: 0px;
      color: #001a41 !important;
    }
    .textField label {
      color: #561651 !important;
    }
    .textField textarea {
      border: none;
      background-color: transparent;
      box-shadow: unset;
      margin: 0px;
      color: #001a41 !important;
    }

    #reactFormButton {
      border: none;
      border-radius: 5px;
      background-color: #561651 !important;
      margin: auto;
      margin-top: 20px;
      color: #fff !important;
      padding: 1rem 1rem 0.6rem 1rem;
      font-size: 1rem;
      :hover {
        cursor: pointer;
        background-color: #321130 !important;
      }
    }

    #myCheckbox {
      color: #561651 !important;
    }
    #checkboxLabel {
      padding-top: 10px;
      padding-bottom: 15px;
      color: #561651;
    }
  }
`;
