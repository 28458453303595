import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import Events from "./Events.js";
import Experiences from "./Experiences.js";
import CovidAlert from "./CovidAlert.js";
import SearchBar from "./components/SearchBar/SearchBar";
import ContactUs from "./ContactUs.js";
import Footer from "./Footer.js";
import Timeline from "./components/myTimeline/Timeline.js";
import "./style.css";
import Testimonials from "./Testimonials.js";
import GaNews from "./GaNews.js";

if (document.getElementById("rootEvents")) {
  ReactDOM.render(<Events />, document.getElementById("rootEvents"));
}

if (document.getElementById("rootExperiences")) {
  ReactDOM.render(<Experiences />, document.getElementById("rootExperiences"));
}

if (document.getElementById("timelineRoot")) {
  ReactDOM.render(<Timeline />, document.getElementById("timelineRoot"));
}

// if (document.getElementById("COVID")) {
//   ReactDOM.render(<CovidAlert />, document.getElementById("COVID"));
// }

if (document.getElementById("demoSearch")) {
  ReactDOM.render(<SearchBar />, document.getElementById("demoSearch"));
}
if (document.getElementById("reactFooter")) {
  ReactDOM.render(<Footer />, document.getElementById("reactFooter"));
}
if (document.getElementById("contactUs")) {
  ReactDOM.render(<ContactUs />, document.getElementById("contactUs"));
}

if (document.getElementById("testimonials-react")) {
  ReactDOM.render(
    <Testimonials />,
    document.getElementById("testimonials-react")
  );
}
if (document.getElementById("ga-news-react")) {
  ReactDOM.render(<GaNews />, document.getElementById("ga-news-react"));
}
