import React from 'react';
import {Ballon,NewsText,NewsTitle,NewsReadMore} from './GaNewsBoardStyles'

const GaNewsBoard = ({text,title,readMoreLink}) => {

  return <>
    <Ballon>
      <NewsTitle>
        {title}
      </NewsTitle>
      <NewsText>
        {`${text} `}
        {readMoreLink && <NewsReadMore href={readMoreLink}>Read More</NewsReadMore>}
      </NewsText>
    </Ballon>
  </>;
};

export default GaNewsBoard;